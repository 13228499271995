import * as React from 'react'
import Layout from '../../components/layout'
import '../../styles/style.scss'

const styles = {

  orderedList: {
    display: "flex",
    justifyContent: "space-between",
    margin: "150px",
  },

  lines: {
    backgroundColor: "#529736",
    display: "flex",
    position: "relative",
    width: "100%"
  },

  lineOne: {
    animation: "fadeIn .75s ease-in-out forwards",
    justifyContent: "flex-start",
    opacity: 0,
  },
  lineTwo: {
    animation: "fadeIn .75s ease-in-out 1s forwards",
    justifyContent: "center",
    opacity: 0,
  },
  lineThree: {
    animation: "fadeIn .75s ease-in-out 1.75s forwards",
    justifyContent: "flex-end",
    opacity: 0,
  },

  greenCircles: {
    alignItems: "center",
    backgroundColor: "#529736",
    borderRadius: "100%",
    color: "white",
    display: "flex",
    justifyContent: "center",
    opacity: "0",
    position: "absolute",
    height: "150px",
    top: "-75px",
    width: "150px"
  },

  circleOne: {
    animation: "slideDown .75s ease-in-out forwards",
    backgroundColor: "#b2d235",
  },

  circleTwo: {
    animation: "slideDown .75s ease-in-out 1s forwards",
    backgroundColor: "#529836",
  },

  circleThree: {
    animation: "slideDown .75s ease-in-out 1.75s forwards",
    backgroundColor: "#02746d",
  },

  circleContent: {
    position: "absolute",
    textAlign: "center",
    top: "125%",
    width: "150%"

  }
}

const ThreeStepProcess = () => {
  return (
      <Layout pageTitle="Benchmarking" nextBottomSlideBtn="Prioritization" nextBottomSlideBtnLink="/Prioritization" id="benchmarking">
        <h2>Easy 3-Step Process</h2>
        <div>
          <ol style={styles.orderedList}>
            <li style={ {...styles.lines, ...styles.lineOne, padding:"1px 1px 1px 0"}}>
              <div style={{...styles.greenCircles, ...styles.circleOne}}>
                <span style={{ fontWeight: "600", }} >Step 1</span>
                <div style={styles.circleContent}>
                  <p>We analyze two years of your energy consumption data</p>
                </div>
              </div>
            </li>
            <li style={ {...styles.lines, ...styles.lineTwo, padding:"1px 1px 1px 1"} }>
              <div style={{...styles.greenCircles, ...styles.circleTwo}}>
                <span style={{ fontWeight: "600", }}>Step 2</span>
                <div style={styles.circleContent}>
                  <p>We compare this data against industry peers</p>
                </div>
              </div>
            </li>
            <li style={ {...styles.lines, ...styles.lineThree, padding:"1px 0px 1px 1px"} }>
              <div style={{...styles.greenCircles, ...styles.circleThree}}>
                <span style={{ fontWeight: "600", }}>Step 3</span>
                <div style={styles.circleContent}>
                  <p>We create a detailed baseline energy and benchmarking profile</p>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </Layout>
  )
}

export default ThreeStepProcess